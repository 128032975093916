:root {
  --white: #ffffff;
  --black: #000000;
  --light-transparent: rgba(255, 255, 255, 0.75);
  --dark-transparent: rgba(255, 255, 255, 0.3);
  --amex-blue: #006cb9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
  overflow-x: hidden;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

* ::-webkit-scrollbar {
  display: none;
}

button,
a,
ul,
ol,
li {
  all: unset;
}

button,
a {
  cursor: pointer;
}

/* temporary banner */

.banner {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background: #eee; */
}

.banner a {
  text-decoration: underline;
}

/* navbar */

.navbar {
  width: 100%;
  background: linear-gradient(15deg, #303c6d, var(--amex-blue) 54%, #303c6d);
}

@keyframes rotateLogo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar a {
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
}

.navbar img {
  width: 7.5rem;
  margin: 0 0 10px;
  animation: rotateLogo 15s linear infinite;
  position: relative;
}

.navbar h1 {
  color: var(--white);
  letter-spacing: 2.5px;
  font-size: 2.75rem;
  position: relative;
  text-align: center;
  margin: 10px 0 0;
}

.navbar img:hover {
  animation-play-state: paused;
}

/* homepage video */

.homepage-container {
  position: relative;
  height: auto;
  min-height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homepage-container span {
  color: var(--black);
  display: block;
  font-size: 1.5rem;
  filter: brightness(0.6);
  margin-top: 15px;
}

.homepage-container span:first-child {
  margin-top: 0;
}

/* logo component */

.logo-container {
  width: 100%;
  position: relative;
  z-index: 100;
  background: var(--white);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  place-content: center;
  /* gap: 5px; */
}

.logo-container .card {
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  border: 0.5px solid var(--black);
  /* border-radius: 15px;
  margin: 5px; */
}

.logo-container .card .circle {
  position: relative; /* Using relative positioning now */
  z-index: 2; /* Keeping it on top initially */
  width: 100%;
  height: 100%;
  /* border-radius: 15px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: z-index 0s 0.5s; /* Delay z-index change to after the logo disappears */
}

.logo-container .card .circle .logo {
  width: 70%;
  transition: transform 0.5s, opacity 0.5s;
}

.card:hover .circle .logo {
  transform: scale(0);
  opacity: 0;
}

.logo-container .card .circle .logo[alt="papr news"],
.logo-container .card .circle .logo[alt="cba-pharma"],
.logo-container .card .circle .logo[alt="Osprey Minerals"],
.logo-container .card .circle .logo[alt="landmark"],
.logo-container .card .circle .logo[alt="lanterra"] {
  width: 100%;
}

.logo-container .card .content {
  position: absolute; /* Position content on top of logo */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Hidden initially using opacity */
  visibility: hidden; /* Using visibility to make sure it doesn't capture mouse events when not shown */
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; /* Transitioning opacity and visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.card:hover .content {
  z-index: 100;
  opacity: 1;
  visibility: visible;
}

.content h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content h2::-webkit-scrollbar {
  display: none;
}

.content p {
  font-size: 12px;
  margin: 5px 0;
  overflow-y: auto;
  padding: 0 10px;
  height: auto;
  max-height: 65%;
}

.content a {
  color: var(--white);
  background: var(--amex-blue);
  padding: 5px 10px;
  border-radius: 15px;
  margin: 10px 0;
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  font-weight: 800;
  transition: 0.5s ease-in-out;
}

.content a:hover {
  background: var(--black);
}

.logo-container img {
  margin: 25px;
  padding: 5px;
}

/* footer */

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  z-index: 100;
  color: var(--white);
  position: relative;
  max-height: 650px;
  overflow: hidden;
  background: linear-gradient(90deg, #303c6d, var(--amex-blue) 54%, #303c6d);
}

.bubble {
  position: absolute;
  border-radius: 100%;
}
.one {
  background: linear-gradient(
    to bottom,
    rgba(40, 159, 244, 0.4) 0,
    rgba(40, 159, 244, 0.32) 27%,
    rgba(40, 159, 244, 0.09) 77%,
    rgba(40, 159, 244, 0.02) 100%
  );
  opacity: 0.2;
  opacity: 0.7;
  width: 1400px;
  height: 1400px;
  right: 43.1%;
  top: -58%;
  left: auto;
  bottom: auto;
  z-index: 25;
}

.two {
  opacity: 0.3;
  background: linear-gradient(
    to bottom,
    rgba(40, 159, 244, 0.4) 0,
    rgba(40, 159, 244, 0.32) 27%,
    rgba(40, 159, 244, 0.09) 77%,
    rgba(40, 159, 244, 0.02) 100%
  );
  width: 824px;
  height: 815px;
  left: 38.5%;
  bottom: -58.7%;
  z-index: 35;
}

.footer .contact-layout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* min-height: 500px; */
  color: var(--white);
  z-index: 500;
  position: relative;
}

/* email component */

.contact-container {
  min-width: 280px;
  height: 400%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  margin: 25px 20px 0;
  border: 1px solid var(--light-transparent);
  border-radius: 15px;
}

.contact-container address {
  /* width: 150px; */
  height: auto;
  text-align: center;
  font-size: 1rem;
  margin: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
}

.contact-container .address .location {
  color: var(--white);
  filter: brightness(1);
  font-size: 22px;
  line-height: 1.5;
}

.contact-container .address .location a {
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
}

.contact-container .address .location a:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Slight background on hover */
}

.contact-container .hyperlink-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 10px; /* Spacing between links */
  width: 100%;
}

.contact-container .hyperlink-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 55px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.contact-container .hyperlink-container a:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Slight background on hover */
}

.contact-container .hyperlink-container a img {
  width: 25px;
  transition: width 0.3s;
  margin-right: 15px;
  filter: invert(1);
}

.contact-container .hyperlink-container a span {
  font-size: 1.3rem;
  transition: font-size 0.3s; /* Smooth transition */
  margin: 0;
  color: var(--white);
  text-decoration: underline;
  filter: brightness(1);
}

.contact-container a {
  width: 100%;
  line-height: 1.5;
}

form {
  /* height: 400px; */
  width: 600px;
  border: 1px solid var(--light-transparent);
  border-radius: 15px;
  padding: 10px;
  margin: 25px 20px 10px;
}

form .inputBox-container {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

form .inputBox-container .spacer {
  height: 1px;
  width: 95%;
  background: var(--white);
}

form .inputBox {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form .inputBox input {
  all: unset;
  width: 100%;
  height: 100%;
  padding: 0 0 0 20px;
  font-size: 18px;
  overflow: hidden;
  color: var(--white);
  cursor: pointer;
}

form .inputBox input::placeholder {
  font-size: 18px;
  color: var(--white);
}

form .inputBox .inputBox-message-input {
  font-size: 18px;
}

form .inputBoxBtn-Container {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form .inputBoxBtn {
  background: var(--white);
  color: var(--amex-blue);
  font-size: 22px;
  padding: 5px 10px;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
  border: 1px solid var(--white);
}

form .inputBoxBtn:hover {
  background: var(--black);
  color: var(--white);
}

.footer .last-p {
  width: 100%;
  text-align: center;
  margin: 15px 0;
}

/* website container */

.website-container {
  width: 100%;
  /* background: var(--black); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 15px;
  z-index: 500;
}

.website-container p {
  font-size: 16px;
}

.website-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  cursor: pointer;
}

.website-container a img {
  height: 100%;
}

/* animations */

.navbar a img {
  visibility: hidden;
  transform: scale(0);
}

.homepage-heading {
  transform: translateY(-100%);
  visibility: hidden;
}

.homepage-subheading {
  transform: translateY(-100%);
  visibility: hidden;
}

.contact-container {
  visibility: hidden;
  transform: translateX(-100%);
}

.form {
  visibility: hidden;
  transform: translateX(100%);
}

@media screen and (min-width: 1263px) {
  .contact-container .address {
    text-align: center;
  }
}

@media screen and (max-width: 1263px) {
  .footer {
    max-height: 150vh;
  }
}

@media screen and (max-width: 768px) {
  .homepage-container h1 {
    margin: 0 0 10px;
  }

  .homepage-container h1 {
    font-size: 2rem;
  }

  .logo-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* gap: 10px; */
  }

  .logo-container .card:hover {
    /* width: 90%; */
    /* min-height: 350px; */
    max-height: 500px;
    transition-delay: 0;
  }

  .content h2 {
    font-size: 1.25rem;
  }

  .content p {
    font-size: 1em;
  }

  .content a {
    font-size: 1em;
  }

  .logo-container .card .circle .logo {
    width: 55%;
  }

  .logo-container .card .circle .logo[alt="papr news"],
  .logo-container .card .circle .logo[alt="cba-pharma"],
  .logo-container .card .circle .logo[alt="Osprey Minerals"],
  .logo-container .card .circle .logo[alt="landmark"],
  .logo-container .card .circle .logo[alt="lanterra"] {
    width: 60%;
  }

  /* footer */

  .contact-container .address .location {
    margin: 10px auto;
  }
}

/* For smaller screens like mobile */
@media screen and (max-width: 480px) {
  .homepage-container span {
    font-size: 18px;
  }

  .logo-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* gap: 5px; */
  }
}
